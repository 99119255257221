import {useIntl} from "react-intl";
import Selector from "../../Selector/Selector";

function ToneField({tone, setTone}) {

    const intl = useIntl();


    return (<>
            <label>{intl.formatMessage({id: 'creator.fields.tone'})}</label>
            <Selector
                options={[
                    {'value': 0, label:intl.formatMessage({id: 'creator.fields.tone.friendly'})},
                    {'value': 1, label:intl.formatMessage({id: 'creator.fields.tone.professional'})},
                    {'value': 2, label:intl.formatMessage({id: 'creator.fields.tone.bold'})},
                    {'value': 3, label:intl.formatMessage({id: 'creator.fields.tone.persuasive'})},
                    {'value': 4, label:intl.formatMessage({id: 'creator.fields.tone.conversational'})},
                ]}
                value={tone}
                onChange={(option) => setTone(option.value)}
                isSearchable={false}
            />
        </>
    );
}

export default ToneField;