import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import axios from "axios";
import errorParser from "../../../ErrorParser/ErrorParser";
import LoadingComp from "../../../LoadingComp/LoadingComp";
import PreviewCreation from "../PreviewCreation/PreviewCreation";

function CreationsTab({toolId, creations, setCreations, afterToggleFavorite}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const [initialLoading, setInitialLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        if(creations.length === 0) {
            return
        }

        setInitialLoading(true)

        let payload = {
            type: toolId,
            show_last: creations.length
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/creator/creations/history", payload, {
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                        setCreations(response.data.creations)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setInitialLoading(false)
    }

    return (<div className={"tab"}>
            {
                initialLoading ?
                    <LoadingComp/>
                    :
                    creations && creations.length > 0 ?
                        <>
                            {
                                creations.map((creation, i) => (
                                    <PreviewCreation
                                        key={creation.id}
                                        creation={creation}
                                        afterToggleFavorite={afterToggleFavorite}
                                    />
                                ))
                            }
                        </>
                        :
                        <div className={"ph"}>
                            <img src={'/assets/images/creator/creations_placeholder.svg'} alt={""}/>
                            <p>{intl.formatMessage({id: 'creator.preview.creations.placeholder'})}</p>
                        </div>
            }
        </div>
    );
}

export default CreationsTab;