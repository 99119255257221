import './Loading.scss'
import ClipLoader from "react-spinners/ClipLoader";

function LoadingComp({white = false}) {
    return (
        <div className={"m-auto loader big"}>
            <ClipLoader color={white ? '#ffffff' : '#3FCAB2'} loading={true} size={25} />
        </div>
    );
}

export default LoadingComp;