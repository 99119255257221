import './Selector.scss'
import Select from 'react-select'

function Selector({options, onChange, value, ...props}) {



    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const getSelectedOptionByValue = (value) => {
        let result = null

        if(options[0] && options[0].options) {
            options.forEach((group,i) => {
                let newResult = group.options.find((item) => item.value === value)
                if(newResult) {
                    result = newResult
                }
            })
        } else {
            result = options.find((item) => item.value === value)
        }

        return result
    }

    return (<Select
                classNamePrefix={"selector"}
                options={options}
                onChange={onChange}
                defaultValue={getSelectedOptionByValue(value)}
                formatGroupLabel={formatGroupLabel}
                {...props}
            />
    );
}

export default Selector;
