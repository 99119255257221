import React, {useEffect, useRef, useState} from "react";
import './PreviewContainer.scss'
import {useIntl} from "react-intl";
import HistoryTab from "./Tabs/HistoryTab";
import FavoritesTab from "./Tabs/FavoritesTab";
import axios from "axios";
import errorParser from "../../ErrorParser/ErrorParser";
import CreationsTab from "./Tabs/CreationsTab";
import LoadingComp from "../../LoadingComp/LoadingComp";

function PreviewContainer({creations, setCreations, toolId}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const contentContRef = useRef()
    const scrollInner = document.getElementById("s-inner")
    const [activeTab, setActiveTab] = useState(0)
    const [favoriteCount, setFavoriteCount] = useState(0)
    const [historyCount, setHistoryCount] = useState(0)
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {
        setActiveTab(0)
        resetScroll()
        getCountersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creations])

    const getCountersData = async ( ) => {

        let payload = {
            type: toolId
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/creator/creations/counters", payload,{
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                    setFavoriteCount(response.data.favorite)
                    setHistoryCount(response.data.history)
                    setInitialLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const resetScroll = () => {
        scrollInner?.scrollTo(0, 0)
    }

    if(initialLoading) {
        return <div>
            <LoadingComp/>
        </div>
    }

    return (<div className={"preview-cont"} ref={contentContRef}>

            <div className={"head"}>
                <div className={"item " + (activeTab === 0 ? 'active' : '')}
                     onClick={() => setActiveTab(0)}>{intl.formatMessage({id: 'creator.preview.tabs.creations'})}</div>
                <div className={"item " + (activeTab === 1 ? 'active' : '')} onClick={() => setActiveTab(1)}>{intl.formatMessage({id: 'creator.preview.tabs.favorite'})} ({favoriteCount})
                </div>
                <div className={"item " + (activeTab === 2 ? 'active' : '')}
                     onClick={() => setActiveTab(2)}>{intl.formatMessage({id: 'creator.preview.tabs.history'})} ({historyCount})</div>
            </div>

            <div className={"content"}>
                {activeTab === 0 ?
                    <CreationsTab
                        creations={creations}
                        setCreations={setCreations}
                        toolId={toolId}
                        afterToggleFavorite={getCountersData}
                    />
                    : null
                }

                {
                    activeTab === 1 ?
                        <FavoritesTab
                            toolId={toolId}
                            afterToggleFavorite={getCountersData}
                        />
                        : null
                }

                {
                    activeTab === 2 ?
                        <HistoryTab
                            toolId={toolId}
                            afterToggleFavorite={getCountersData}
                        />
                    :null
                }
            </div>
        </div>
    );
}

export default PreviewContainer;