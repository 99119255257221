import {useIntl} from "react-intl";
import Navbar from '../Navbar/Navbar'
import './Layout.scss'
import {NavLink, Outlet} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import LoadingComp from "../LoadingComp/LoadingComp";

function Layout() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [accountDetails, setAccountDetails] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        await axios.get(process.env.REACT_APP_SERVER + "/api/account", {
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                    setAccountDetails(response.data.data)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setLoading(false)
    }

    return (
        <div className={"layout"} id={"layout"}>
            <Navbar/>

            <div className={"cont"}>
                <div className={"left-bar"}>

                    {loading ?
                    <LoadingComp/>
                        :

                    <div className={"menu"}>
                        <div className={"top-items"}>
                            <NavLink to="/">
                                <div className={"item"}><i className="fa-solid fa-table-columns pe-1"></i> {intl.formatMessage({id: 'menu.dashboard'})}</div>
                            </NavLink>
                            <NavLink to="/creator">
                                <div className={"item"}><i className="fa-regular fa-pen-to-square pe-1"></i> {intl.formatMessage({id: 'menu.creator'})}</div>
                            </NavLink>
                            {accountDetails && accountDetails.email === 'contact@copybase.io' ?
                                null
                                :
                            <NavLink to="/subscription">
                                <div className={"item"}><i className="fa-regular fa-credit-card pe-1"></i> {intl.formatMessage({id: 'menu.subscription'})}</div>
                            </NavLink>
                            }
                            <NavLink to="/account">
                                <div className={"item"}><i className="fa-regular fa-user pe-1"></i> {intl.formatMessage({id: 'menu.account'})}</div>
                            </NavLink>

                        </div>

                        <hr/>
                        <a href={"https://support.copybase.io"} target={"_blank"} rel={"noreferrer"}>
                            <div className={"item"}><i className="fa-regular fa-question-circle pe-1"></i> {intl.formatMessage({id: 'menu.support'})}</div>
                        </a>
                        <div className={"item logout"} onClick={() => {
                            localStorage.clear();
                            window.location.replace("/");
                        }}><i className={"fas fa-right-from-bracket pe-1"}/> {intl.formatMessage({id: 'menu.logout'})}
                        </div>


                    </div>}

                </div>
                <div className={"main-cont"}>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default Layout;
