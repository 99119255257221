import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar'
import './LayoutGuest.scss'

function LayoutGuest() {

    return (
        <div className={"layout-guest"}>
            <Navbar
                guest
            />
            <Outlet/>
        </div>
    );
}

export default LayoutGuest;