import React, {useContext, useState} from "react";
import './Navbar.scss'
import {useIntl} from "react-intl";
import LanguageContext from "../../contexts/LanguageContext";
import ModalComp from "../ModalComp/ModalComp"
import InputLanguageField from "../Creator/Fields/InputLanguageField";
import OutputLanguageField from "../Creator/Fields/OutputLanguageField";
import {getLanguageIconByValue} from "../../functions/general";
import {Link, NavLink, useNavigate} from "react-router-dom";

function Navbar({guest = false}) {

    const intl = useIntl();
    const navigate = useNavigate()
    const {
        language,
        inputLanguage,
        setInputLanguage,
        outputLanguage,
        setOutputLanguage,
        showLanguageModal,
        setShowLanguageModal,
        showSubscriptionLimitModal,
        setShowSubscriptionLimitModal
    } = useContext(LanguageContext);

    const substriptionType = localStorage.getItem('subscriptionType') ? localStorage.getItem('subscriptionType') : '1'

    const [showMobileMenu, setShowMobileMenu] = useState(false)

    return (
        <div className={"navbar"}>
            <div className={"logo-cont"}>
                <Link to={"/"}>
                    <img src={"/assets/images/logo.svg"} alt={""}/>
                </Link>
            </div>

            <div className={"right-cont"}>
                {
                    !guest ?
                        <>
                            {/*
                            <div className={"item lang"} onClick={() => setShowLanguageModal(true)}>
                                {getLanguageIconByValue(language)}
                            </div>
                            */}

                            <div className={"item user"} onClick={() => navigate('/account')}>
                                <i className={"fas fa-user"}/>
                            </div>

                            {
                                substriptionType === '1' ?
                                    <div className={"item buyPro"} onClick={() => navigate('/subscription')}>
                                        <i className={"fas fa-circle-check pe-2"}/> {intl.formatMessage({id: 'navbar.buyPro'})}
                                    </div>
                                    :
                                    <div className={"item buyPro"} onClick={() => navigate('/subscription')}>
                                        <i className={"fas fa-circle-check pe-2"}/> PRO
                                    </div>
                            }
                        </>
                        : null
                }
            </div>

            {
                !guest ?
                    <div className={"right-cont-mobile"}>
                        <div className={"item lang"} onClick={() => setShowMobileMenu(true)}>
                            <i className="fa-solid fa-bars"></i>
                        </div>
                    </div>
                    : null
            }

            <ModalComp
                isOpen={showLanguageModal}
                onRequestClose={() => setShowLanguageModal(false)}
                className={"language"}
            >
                <div className={"d-flex flex-column justify-content-center pb-3 flex-grow-1"}>
                    {/* Disable for now
                    <InterfaceLanguageField
                        language={language}
                        setLanguage={setLanguage}
                    />
                    */}

                    {!guest ?
                        <>

                            <div className={"mb-4"}>
                                <InputLanguageField
                                    inputLanguage={inputLanguage}
                                    setInputLanguage={setInputLanguage}
                                />
                            </div>
                            <OutputLanguageField
                                outputLanguage={outputLanguage}
                                setOutputLanguage={setOutputLanguage}
                            />
                        </>
                        : null
                    }
                </div>
            </ModalComp>

            <ModalComp
                isOpen={showSubscriptionLimitModal}
                onRequestClose={() => setShowSubscriptionLimitModal(false)}
                className={"subscription-limit"}
            >
                <div className={"d-flex flex-column justify-content-center align-items-center flex-grow-1"}>

                    <img src={"/assets/images/subscription_limit.svg"} className={"image"} alt=""/>
                    <h5 className={"text-center"}>{intl.formatMessage({id: 'subscription_limit_modal.title'})}</h5>
                    <p className={"text-center mt-2"}>{intl.formatMessage({id: 'subscription_limit_modal.description'})}</p>

                    <button type={"button"} className={"btn btn-primary mt-5"} onClick={() => {
                        setShowSubscriptionLimitModal(false)
                        navigate('/subscription')
                    }}>
                        {intl.formatMessage({id: 'subscription_limit_modal.button'})}
                    </button>

                </div>
            </ModalComp>


            {
                showMobileMenu ?
                    <div className={"mobile-menu"}>

                        <div className={"header"}>

                            <div className={"mobile-logo-cont"}>
                                <Link to={"/"}>
                                    <img src={"/assets/images/logo.svg"} alt={""}/>
                                </Link>
                            </div>

                            <div className={"hamburger"} onClick={() => setShowMobileMenu(false)}>
                                <i className="fa-solid fa-bars"></i>
                            </div>
                        </div>

                        <div className={"menu"}>

                            <NavLink to="/" onClick={() => setShowMobileMenu(false)}>
                                <div className={"item"}><i className="fa-solid fa-table-columns pe-1"></i> {intl.formatMessage({id: 'menu.dashboard'})}</div>
                            </NavLink>
                            <NavLink to="/creator" onClick={() => setShowMobileMenu(false)}>
                                <div className={"item"}><i className="fa-regular fa-pen-to-square pe-1"></i> {intl.formatMessage({id: 'menu.creator'})}</div>
                            </NavLink>
                            <NavLink to="/subscription" onClick={() => setShowMobileMenu(false)}>
                                <div className={"item"}><i className="fa-regular fa-credit-card pe-1"></i> {intl.formatMessage({id: 'menu.subscription'})}</div>
                            </NavLink>
                            <NavLink to="/account" onClick={() => setShowMobileMenu(false)}>
                                <div className={"item"}><i className="fa-regular fa-user pe-1"></i> {intl.formatMessage({id: 'menu.account'})}</div>
                            </NavLink>

                            <div className={"item"} onClick={() => setShowLanguageModal(true)}>
                                {getLanguageIconByValue(language)}
                            </div>

                            <div className={"item logout"} onClick={() => {
                                localStorage.clear();
                                window.location.replace("/");
                            }}><i
                                className={"fas fa-right-from-bracket pe-1"}/> {intl.formatMessage({id: 'menu.logout'})}
                            </div>

                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

export default Navbar;
