import Modal from "react-modal"
import './ModalComp.scss'

export default function ModalComp({...props}) {
    const customStyles = {
        content: {
            ...props.styleContent
        },
        overlay: {

        }
    };

    return (<Modal
        closeTimeoutMS={200}
        style={customStyles}
        {...props}
    >

        <div className={"head"}>
            <div className={"title"}></div>
            <div className={"close"} onClick={() => props.onRequestClose()}>
                <span>✖️</span>
            </div>
        </div>

        {props.children}

    </Modal>)
}