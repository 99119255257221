export const availableLanguages = [
    {"value": "af", "label": "Afrikaans", symbol: "🇿🇦", country: "za"},
    {"value": "ak", "label": "Akan"},
    {"value": "sq", "label": "Albanian", symbol: "🇦🇱", country: "al"},
    {"value": "am", "label": "Amharic", symbol: "🇪🇹", country: "et"}, //ethiopia
    {"value": "ar", "label": "Arabic", symbol: "🇦🇪", country: "ae", rtl: true},
    {"value": "hy", "label": "Armenian", symbol: "🇦🇲", country: "am"},
    {"value": "as", "label": "Assamese"},
    {"value": "ay", "label": "Aymara", symbol: "🇵🇪", country: "pe"},
    {"value": "az", "label": "Azerbaijani", symbol: "🇦🇿", country: "az"},
    {"value": "bm", "label": "Bambara"},
    {"value": "eu", "label": "Basque", symbol: "🇪🇸", country: "es"},
    {"value": "be", "label": "Belarusian", symbol: "🇧🇾", country: "by"},
    {"value": "bn", "label": "Bengali", symbol: "🇧🇩", country: "bd"},
    {"value": "bho", "label": "Bhojpuri"},
    {"value": "bs", "label": "Bosnian", symbol: "🇧🇦", country: "ba"},
    {"value": "bg", "label": "Bulgarian", symbol: "🇧🇬"},
    {"value": "ca", "label": "Catalan", symbol: "🇦🇩"},
    {"value": "ceb", "label": "Cebuano"},
    {"value": "ny", "label": "Chichewa"},
    {"value": "zh", "label": "Chinese (Simplified)", symbol: "🇨🇳", country: "cn"},
    {"value": "zh-TW", "label": "Chinese (Traditional)", symbol: "🇨🇳", country: "cn"},
    {"value": "co", "label": "Corsican"},
    {"value": "hr", "label": "Croatian", symbol: "🇭🇷", country: "hr"},
    {"value": "cs", "label": "Czech", symbol: "🇨🇿", country: "cz"},
    {"value": "da", "label": "Danish", symbol: "🇩🇰", country: "dk"},
    {"value": "dv", "label": "Divehi", symbol: "🇲🇻", country: "mv", rtl:true},
    {"value": "doi", "label": "Dogri"},
    {"value": "nl", "label": "Dutch", symbol: "🇳🇱", country: "nl"},
    {"value": "en", "label": "English (best results)", symbol: "🇺🇸", country: "us"},
    {"value": "eo", "label": "Esperanto"},
    {"value": "et", "label": "Estonian", symbol: "🇪🇪", country: "ee"},
    {"value": "ee", "label": "Ewe"},
    {"value": "tl", "label": "Filipino"},
    {"value": "fi", "label": "Finnish"},
    {"value": "fr", "label": "French", symbol: "🇫🇷", country: "fr"},
    {"value": "fy", "label": "Frisian"},
    {"value": "gl", "label": "Galician"},
    {"value": "lg", "label": "Ganda"},
    {"value": "ka", "label": "Georgian", symbol: "🇬🇪", country: "ge"},
    {"value": "de", "label": "German", symbol: "🇩🇪", country: "de"},
    {"value": "gom", "label": "Goan Konkani"},
    {"value": "el","label": "Greek", symbol: "🇬🇷", country: "gr"},
    {"value": "gn", "label": "Guarani", symbol: "🇧🇴", country: "py"}, //paraguay
    {"value": "gu", "label": "Gujarati"},
    {"value": "ht","label": "Haitian Creole", symbol: "🇭🇹", country: "ht"},
    {"value": "ha", "label": "Hausa", rtl:true},
    {"value": "haw", "label": "Hawaiian"},
    {"value": "iw", "label": "Hebrew", symbol: "🇮🇱", country: "il", rtl:true},
    {"value": "hi", "label": "Hindi", symbol: "🇮🇳", country: "in"},
    {"value": "hmn", "label": "Hmong"},
    {"value": "hu", "label": "Hungarian", symbol: "🇭🇺", country: "hu"},
    {"value": "is", "label": "Icelandic", symbol: "🇮🇸", country: "is"},
    {"value": "ig", "label": "Igbo"},
    {"value": "ilo", "label": "Iloko"},
    {"value": "id", "label": "Indonesian", symbol: "🇮🇩", country: "id"},
    {"value": "ga", "label": "Irish", symbol: "🇮🇪", country: "ie"},
    {"value": "it", "label": "Italian", symbol: "🇮🇹", country: "it"},
    {"value": "ja", "label": "Japanese", symbol: "🇯🇵", country: "jp"},
    {"value": "jw", "label": "Javanese"},
    {"value": "kn", "label": "Kannada"},
    {"value": "kk", "label": "Kazakh", symbol: "🇰🇿", country: "kz"},
    {"value": "km", "label": "Khmer", symbol: "🇰🇭", country: "kh"},
    {"value": "rw", "label": "Kinyarwanda", symbol: "🇰🇬", country: "rw"},
    {"value": "ko", "label": "Korean", symbol: "🇰🇷", country: "kr"},
    {"value": "kri", "label": "Krio"},
    {"value": "ku","label": "Kurdish (Kurmanji)", symbol: "🇮🇷", country: "sy", rtl:true}, //syria
    {"value": "ckb", "label": "Kurdish (Sorani)", symbol: "🇮🇶", country: "iq", rtl:true},
    {"value": "ky", "label": "Kyrgyz"},
    {"value": "lo", "label": "Lao", symbol: "🇱🇦", country: "la"},
    {"value": "la", "label": "Latin"},
    {"value": "lv", "label": "Latvian", symbol: "🇱🇻", country: "lv"},
    {"value": "ln", "label": "Lingala"},
    {"value": "lt", "label": "Lithuanian", symbol: "🇱🇹", country: "lt"},
    {"value": "lb", "label": "Luxembourgish", symbol: "🇱🇺", country: "lu"},
    {"value": "mk", "label": "Macedonian", symbol: "🇲🇰", country: "mk"},
    {"value": "mai", "label": "Maithili"},
    {"value": "mg", "label": "Malagasy", symbol: "🇲🇬", country: "mg"},
    {"value": "ms", "label": "Malay", symbol: "🇲🇴", country: "bn"},
    {"value": "ml", "label": "Malayalam", symbol: "🇲🇳", country: "in"},
    {"value": "mt", "label": "Maltese", symbol: "🇲🇹", country: "mt"},
    {"value": "mni-Mtei", "label": "Manipuri (Meitei Mayek)"},
    {"value": "mi", "label": "Maori", symbol: "🇳🇿", country: "nz"},
    {"value": "mr", "label": "Marathi", symbol: "🇲🇵", country: "in"},
    {"value": "lus", "label": "Mizo"},
    {"value": "mn", "label": "Mongolian", symbol: "🇲🇳", country: "mn"},
    {"value": "my", "label": "Myanmar (Burmese)", symbol: "🇲🇲", country: "mm"},
    {"value": "ne", "label": "Nepali", symbol: "🇳🇵", country: "np"},
    {"value": "nso", "label": "Northern Sotho"},
    {"value": "no", "label": "Norwegian", symbol: "🇳🇴", country: "no"},
    {"value": "or", "label": "Odia (Oriya)", symbol: "🇵🇬", country: "in"},
    {"value": "om", "label": "Oromo"},
    {"value": "ps", "label": "Pashto", country: "af", rtl:true},
    {"value": "fa", "label": "Persian", symbol: "🇮🇷", country: "ir", rtl:true},
    {"value": "pl", "label": "Polish", symbol: "🇵🇱", country: "pl"},
    {"value": "pt", "label": "Portuguese", symbol: "🇵🇹", country: "pt"},
    {"value": "pa", "label": "Punjabi", symbol: "🇵🇰", country: "pk"},
    {"value": "qu", "label": "Quechua"},
    {"value": "ro", "label": "Romanian", symbol: "🇷🇴", country: "ro"},
    {"value": "ru", "label": "Russian", symbol: "🇷🇺", country: "ru"},
    {"value": "sm", "label": "Samoan", symbol: "🇸🇲", country: "ws"},
    {"value": "sa", "label": "Sanskrit"},
    {"value": "gd", "label": "Scots Gaelic"},
    {"value": "sr", "label": "Serbian", symbol: "🇷🇸", country: "rs"},
    {"value": "st", "label": "Sesotho"},
    {"value": "sn", "label": "Shona"},
    {"value": "sd", "label": "Sindhi", rtl:true},
    {"value": "si", "label": "Sinhala"},
    {"value": "sk", "label": "Slovak", symbol: "🇸🇰", country: "sk"},
    {"value": "sl", "label": "Slovenian", symbol: "🇸🇮", country: "si"},
    {"value": "so", "label": "Somali", symbol: "🇸🇴", country: "so"},
    {"value": "es", "label": "Spanish", symbol: "🇪🇸", country: "es"},
    {"value": "su", "label": "Sundanese"},
    {"value": "sw", "label": "Swahili", symbol: "🇸🇩", country: "tz"},
    {"value": "sv", "label": "Swedish", symbol: "🇸🇪", country: "se"},
    {"value": "tg", "label": "Tajik"},
    {"value": "ta", "label": "Tamil", symbol: "🇱🇰", country: "in"},
    {"value": "tt", "label": "Tatar", symbol: "🇹🇲", country: "ru"},
    {"value": "te", "label": "Telugu", symbol: "🇹🇳", country: "in"},
    {"value": "th", "label": "Thai", symbol: "🇹🇭", country: "th"},
    {"value": "ti", "label": "Tigrinya", symbol: "🇪🇹", country: "er"},
    {"value": "ts", "label": "Tsonga", symbol: "🇿🇦", country: "za"},
    {"value": "tr", "label": "Turkish", symbol: "🇹🇷", country: "tr"},
    {"value": "tk", "label": "Turkmen", symbol: "🇹🇲", country: "tm"},
    {"value": "uk", "label": "Ukrainian", symbol: "🇺🇦", country: "ua"},
    {"value": "ur", "label": "Urdu", symbol: "🇵🇰", country: "pk", rtl:true},
    {"value": "ug", "label": "Uyghur", symbol: "🇨🇳", country: "cn"},
    {"value": "uz", "label": "Uzbek", symbol: "🇺🇿", country: "uz", rtl:true},
    {"value": "vi", "label": "Vietnamese", symbol: "🇻🇳", country: "vn"},
    {"value": "cy", "label": "Welsh", symbol: "🏴󠁧󠁢󠁷󠁬󠁳󠁿", country: "gb-wls"},
    {"value": "xh", "label": "Xhosa", symbol: "🇿🇼", country: "za"},
    {"value": "yi", "label": "Yiddish", symbol: "🇧🇦", country: "ba", rtl:true},
    {"value": "yo", "label": "Yoruba"},
    {"value": "zu", "label": "Zulu", symbol: "🇿🇦", country: "za"},
]