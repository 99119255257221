import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import './ThankYou.scss'
import axios from "axios";
import LoadingComp from "../../components/LoadingComp/LoadingComp";

function ThankYou() {

    const intl = useIntl();
    const query = new URLSearchParams(window.location.search);

    const [response, setResponse] = useState(false)
    const [status, setStatus] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {

        let payload = {
            item: query.get('item'),
            cbreceipt: query.get('cbreceipt'),
            time: query.get('time'),
            cbpop: query.get('cbpop'),
            cbaffi: query.get('cbaffi'),
            cemail: query.get('cemail'),
            ccountry: query.get('ccountry'),
            czip: query.get('czip'),
            cbuid: query.get('cbuid')
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/guest/subscription/clickbank/confirm", payload)
            .then((response) => {
                setStatus(response.data.status)
                setResponse(response.data.data)

                if (response.data.data.token && response.data.data.subscription_type) {
                    localStorage.setItem('jwt', response.data.data.token)
                    localStorage.setItem('subscriptionType', response.data.data.subscription_type)
                }

                if (response.data.data.already_validated) {
                    window.location.replace("/")
                }
            })
            .catch(err => {
                setStatus(0)
            })

        setLoading(false)
    }


    if (loading) {
        return (
            <LoadingComp />
        )
    }

    return (
        <div className={"thank-you"}>

            {
                status === 1 || status === 0 ?
                    <>
                        <div>Your credit card statement will show a charge from CLKBANK*</div>
                        <div className={"icon"}><i className="fa-regular fa-circle-check"></i></div>
                        <h2>{intl.formatMessage({ id: 'thank_you.title' })}</h2>
                        <div className={"description"}>
                            {intl.formatMessage({ id: 'thank_you.notice' })}

                            {
                                response.new_user || status === 0 ?
                                    <div className={"mt-2"}>
                                        <i className="fa-solid fa-envelope"></i> {intl.formatMessage({ id: 'thank_you.new_user_notice' })}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <button type={"button"}
                            className={"btn btn-primary mt-4"}
                            onClick={() => window.location.replace("/")}>
                            {intl.formatMessage({ id: 'thank_you.button' })}
                        </button>

                        <br/><br/>

                        <a href={"https://support.copybase.io"}>
                            <button type={"button"}
                                    className={"btn btn-sm btn-default"}><i className={"fa-regular fa-envelope"}/> {intl.formatMessage({ id: 'thank_you.error.button' })}
                            </button>
                        </a>
                    </>
                    :
                    <>
                        <div className={"icon red"}><i className="fa-solid fa-circle-exclamation"></i></div>
                        <h2>{intl.formatMessage({ id: 'thank_you.error.title' })}</h2>
                        <div className={"description mb-4"}>
                            {intl.formatMessage({ id: 'thank_you.error.notice' })}
                            <br />
                        </div>
                        <a href={"https://support.copybase.io"}>
                            <button type={"button"}
                                className={"btn btn-secondary"}>
                                {intl.formatMessage({ id: 'thank_you.error.button' })}
                            </button>
                        </a>
                    </>
            }

            <br/><br/><br/>
            <div style={{maxWidth:'800px', margin:'auto', fontSize: '0.8rem'}}>
                ClickBank is the retailer of this product. CLICKBANK® is a registered trademark of Click Sales, Inc., a Delaware corporation located at 1444 S. Entertainment Ave., Suite 410 Boise, ID 83709, USA and used by permission. ClickBank's role as retailer does not constitute an endorsement, approval or review of this product or any claim, statement or opinion used in promotion of this product.
                <br/><br/>
                This site and the products and services offered on this site are not associated, affiliated, endorsed, or sponsored by Facebook, Google, Instagram, LinkedIn, or Bing, nor have they been reviewed tested or certified by Facebook, Google, Instagram, LinkedIn, or Bing.
            </div>
        </div>
    );
}

export default ThankYou;
