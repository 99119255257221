import './UpgradeToPro.scss'
import {useIntl} from "react-intl";

function UpgradeToPro({subscriptionDetails, subscriptionTypes}) {

    const intl = useIntl();

    return (<div className={"upgrade-to-pro"}>


            <div className={"row items"}>

                {
                    subscriptionTypes.filter((item) => item.id > 1).map((subscriptionType, i) => (
                        <div className={"col-12 col-lg-12"} key={i}>
                            <div className={"item"} style={{maxWidth:'400px', margin:'auto'}}>
                                <div
                                    className={"title"}>{intl.formatMessage({id: 'subscription.types.' + subscriptionType.id + '.title'})}</div>


                                {subscriptionType.old_price > subscriptionType.price &&
                                <div className={"old-price"}><i
                                    className="fa-solid fa-tag"></i>{intl.formatMessage({id: 'subscription.normal_price'})} ${subscriptionType.old_price}
                                </div>}

                                <div className={"price mt-4 mb-4"}>
                                    <div className={"currency"}>$</div>
                                    <div className={"value"}>{subscriptionType.price}</div>
                                    <div className={"month"}>/{intl.formatMessage({id: 'subscription.month'})}</div>
                                </div>
                                {subscriptionType.old_price > subscriptionType.price &&
                                <div className={"offer"}>
                                    {intl.formatMessage({id: 'subscription.discount.get'})} {subscriptionType.discount}% {intl.formatMessage({id: 'subscription.discount.discount'})}
                                </div>}
                                {/*
                                <div className={"monthly-limit"}>{subscriptionType.monthly_limit.toLocaleString('en-US')} {intl.formatMessage({id: 'subscription.words_per_month'})}</div>
*/}
                                <div className={"features"}>
                                    <div className={"feature"}><i className="fa-solid fa-check"></i> {intl.formatMessage({id: 'subscription.features.best_algorithm'})}</div>
                                    <div className={"feature"}><i className="fa-solid fa-check"></i> {intl.formatMessage({id: 'subscription.features.best_creativity'})}</div>
                                    <div className={"feature"}><i className="fa-solid fa-check"></i> {intl.formatMessage({id: 'subscription.features.access_to_beta_tools'})}</div>
                                    <div className={"feature"}><i className="fa-solid fa-check"></i> {intl.formatMessage({id: 'subscription.features.cancel_anytime'})}</div>
                                 </div>


                                {/*
                                <div className={"description"}>
                                    {intl.formatMessage({id: 'subscription.types.' + subscriptionType.id + '.description'})}
                                </div>*/}

                                {
                                    subscriptionType.id !== subscriptionDetails.type ?
                                        <a href={"https://softclaire.pay.clickbank.net/?cbitems=" + subscriptionType.clickbank_item + "&cbuid=" + subscriptionDetails.user_id}>
                                            <button type={"button"}
                                                    className={"btn btn-primary w-100 mt-4"}>
                                                {intl.formatMessage({id: 'subscription.buy_now'})}
                                            </button>
                                        </a>
                                        :
                                        <button type={"button"} disabled={true}
                                                className={"btn w-100 mt-4"}>
                                            {intl.formatMessage({id: 'subscription.current'})}
                                        </button>
                                }
                            </div>
                        </div>
                    ))
                }

            </div>

        </div>
    );
}

export default UpgradeToPro;
