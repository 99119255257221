import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import './Subscription.scss'
import axios from "axios";
import errorParser from "../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../components/LoadingComp/LoadingComp";
import UpgradeToPro from "../../components/UpgradeToPro/UpgradeToPro";

function Subscription() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [subscriptionDetails, setSubscriptionDetails] = useState({})
    const [usagePercent, setUsagePercent] = useState(0)

    const [subscriptionTypes, setSubscriptionTypes] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()

        const cbscript = document.createElement("script")
        cbscript.setAttribute('id', 'cbscript')
        cbscript.src = "//cbtb.clickbank.net/?vendor=softclaire"
        cbscript.async = true
        document.body.appendChild(cbscript)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        await axios.get(process.env.REACT_APP_SERVER + "/api/subscription", {
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                    setSubscriptionDetails(response.data.data.current_subscription)
                    setSubscriptionTypes(response.data.data.subscription_types)

                    localStorage.setItem('subscriptionType', response.data.data.current_subscription.type + '')

                    let usagePercent = ((response.data.data.current_subscription.monthly_usage * 100) / response.data.data.current_subscription.monthly_limit).toFixed(1)

                    if (response.data.data.current_subscription.monthly_limit === 0) {
                        usagePercent = 0
                    }

                    setUsagePercent(usagePercent)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setLoading(false)
    }

    if (loading) {
        return (
            <LoadingComp/>
        )
    }

    return (
        <div className={"inner subscription"}>


            {/*
             <h2>{intl.formatMessage({id: 'subscription.title'})}</h2>
            <div className={"inner-cont"}>
                <div className={"row"}>
                    <div className={"col-6 col-lg-4"}>
                        <small><strong>{intl.formatMessage({id: 'subscription.current'})}</strong></small>
                        <div className={"h5"}>
                            {
                                subscriptionDetails.type > 1 ?
                                    <div className={"badge bg-primary me-2"}>PRO</div>
                                    : null
                            }
                            {intl.formatMessage({id: 'subscription.types.' + subscriptionDetails.type + '.title'})}</div>
                    </div>
                    <div className={"col-6  col-lg-4"}>
                        <small><strong>{intl.formatMessage({id: 'subscription.monthly_limit'})}</strong></small>
                        <p className={"h5"}>{subscriptionDetails.monthly_limit.toLocaleString('en-US')} {intl.formatMessage({id: 'subscription.words'})}</p>
                    </div>

                    <div className={"col-12  col-lg-4"}>
                        <small><strong>{intl.formatMessage({id: 'subscription.usage'})}</strong></small>
                        <div>
                            {subscriptionDetails.monthly_usage.toLocaleString('en-US')} / {subscriptionDetails.monthly_limit.toLocaleString('en-US')}
                        </div>
                        <div className="progress">
                            <div className="progress-bar" style={{width: usagePercent + '%'}}
                                 aria-valuenow={usagePercent}
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                                {usagePercent}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}


            {
                subscriptionDetails.type === 1 ?
                    <h2 className={"mt"}>{intl.formatMessage({id: 'subscription.upgrade_to_pro'})}</h2>
                    :
                    <h2 className={"mt"}>{intl.formatMessage({id: 'subscription.need_more'})}</h2>
            }
            <div className={"inner-cont pt-4"}>

                <p className={"text-center mb-4"}>
                    {intl.formatMessage({id: 'subscription.upgrade_to_pro.description.1'})}
                    <br/>
                    {intl.formatMessage({id: 'subscription.upgrade_to_pro.description.2'})}
                </p>

                <UpgradeToPro
                    subscriptionDetails={subscriptionDetails}
                    subscriptionTypes={subscriptionTypes}
                />
            </div>

            <div className={"alert alert-info mt-2"}>
                {intl.formatMessage({id: 'subscription.cancel_request_description'})}
            </div>

            <div className={"text-center"}>
                <a href={"https://support.copybase.io"} target={"_blank"} rel={"noreferrer"}>
                    <button type={"button"} className={"btn btn-primary mt-2 m-auto"}>
                        <i className={"fa-regular fa-question-circle"}/> {intl.formatMessage({id: 'subscription.go_to_help_desk'})}
                    </button>
                </a>
            </div>


        </div>
    );
}

export default Subscription;
