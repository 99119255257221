import React from "react";

export default React.createContext({
    language: "en",
    setLanguage: () => {},
    inputLanguage: "en",
    setInputLanguage: () => {},
    outputlanguage: "en",
    setOutputLanguage: () => {},
    showLanguageModal: false,
    setShowLanguageModal: () => {},
    showSubscriptionLimitModal: false,
    setShowSubscriptionLimitModal: () => {}
});