import { toast } from 'react-toastify';

export default function errorParser(data, intl, setShowSubscriptionLimitModal = false) {

    switch (data.response.status) {
        case 401:
            localStorage.clear();
            window.location.replace("/");
            break
        case 400:
            if(data.response && data.response.data && data.response.data.error) {
                switch (data.response.data.error) {
                    case 5001:
                        toast.error(intl.formatMessage({id: 'errors.login.invalid_credentials'}))
                        break
                    case 5002:
                        toast.error(intl.formatMessage({id: 'errors.invalid_recaptcha'}))
                        break
                    case 5003:
                        toast.error(intl.formatMessage({id: 'errors.invalid_reset_password_token'}))
                        break
                    case 5004:
                        // subscription limit exceeded
                        if(setShowSubscriptionLimitModal) {
                            setShowSubscriptionLimitModal(true)
                        }
                        break
                    case 5005:
                        toast.error(intl.formatMessage({id: 'errors.user_banned'}))
                        break
                    default:
                        toast.error(intl.formatMessage({id: 'errors.unknown'}))
                }
            } else {
                toast.error(intl.formatMessage({id: 'errors.unknown'}))
            }

            break
        case 404:
            toast.error(intl.formatMessage({id: 'errors.unknown'}))
            break
        default:
            toast.error(intl.formatMessage({id: 'errors.unknown'}))
    }

}
