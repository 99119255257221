import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import './Dashboard.scss'
import {extractToolsByValue, getTools} from "../../functions/general";
import {useNavigate} from "react-router-dom";
import {availableLanguages} from "../../constants/languages";

function Dashboard() {

    const intl = useIntl();
    const navigate = useNavigate()
    const categoriesAndTools = getTools(intl)

    const popularToolsValues = [
        'product-description',
        'email',
        'facebook-ad-copy',
        'google-ad-copy',
        'rephrase'
    ]

    const recentlyAddedValues = [
        'hashtag',
        'expand-sentence',
        'idea-to-paragraph',
        'article',
        'product-review',
    ]

    const recentlyAddedTools = extractToolsByValue(categoriesAndTools, recentlyAddedValues)
    const popularTools = extractToolsByValue(categoriesAndTools, popularToolsValues)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        })
    }, [])

    return (
        <div className={"inner dashboard"}>

            <h2>{intl.formatMessage({id: 'dashboard.title'})}</h2>

            <div className={"row"}>
                <div className={"col-12"}>

                    <div className={"section-content white"}>
                        <div className={"row"}>
                            <div className={"col"}>
                                <div className={"welcome-icon"}>👋</div>
                                <div className={"welcome-text"}>
                                    {intl.formatMessage({id: 'dashboard.welcome_text'})}<br/><br/>
                                    {intl.formatMessage({id: 'dashboard.welcome_text_1'})}<br/>
                                    {intl.formatMessage({id: 'dashboard.welcome_text_2'})}<br/><br/>
                                    {intl.formatMessage({id: 'dashboard.welcome_text_3'})}<br/><br/>

                                </div>
                            </div>
                            {/*
                            <div className={"col-12 col-lg-6 col-xl-5"}>
                                <iframe
                                    style={{width: '100%', height: '300px'}}
                                    src="https://www.youtube-nocookie.com/embed/eKKvQX3AV7w"
                                    title="CopyBase Tutorial" frameBorder="0"
                                    allow="autoplay; clipboard-write; encrypted-media;picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            </div>*/}
                        </div>
                    </div>
                </div>


                <div className={"col-12"}>
                    <div className={"section-title"}>
                        {intl.formatMessage({id: 'dashboard.popular_tools'})}
                    </div>
                    <div className={"section-content"}>
                        <div className={"tools"}>
                            <div className={"category"}>
                                <div className={"items"}>
                                    {
                                        popularTools.map((optionValue, i) => (
                                            <div key={i}
                                                 className={"item"}
                                                 onClick={() => navigate('/creator/tools/' + optionValue.value)}>
                                                <img src={require('../../assets/images/tools/' + optionValue.icon)}
                                                     alt={""}/>
                                                <div className={"title"}>{optionValue.label}</div>
                                                <div className={"description"}>{optionValue.description}</div>
                                                <div
                                                    className={"button"}>{intl.formatMessage({id: 'creator.open_tool'})}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"col-12"}>
                    <div className={"section-title"}>
                        {intl.formatMessage({id: 'dashboard.recently_added_tools'})}
                    </div>
                    <div className={"section-content"}>
                        <div className={"tools"}>
                            <div className={"category"}>
                                <div className={"items"}>
                                    {
                                        recentlyAddedTools.map((optionValue, i) => (
                                            <div key={i}
                                                 className={"item"}
                                                 onClick={() => navigate('/creator/tools/' + optionValue.value)}>
                                                <img src={require('../../assets/images/tools/' + optionValue.icon)}
                                                     alt={""}/>
                                                <div className={"title"}>{optionValue.label}</div>
                                                <div className={"description"}>{optionValue.description}</div>
                                                <div
                                                    className={"button"}>{intl.formatMessage({id: 'creator.open_tool'})}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Dashboard;
