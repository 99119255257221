import {useIntl} from "react-intl";
import Selector from "../../Selector/Selector";

function NumberOfResponses({numberOfResponses, setNumberOfResponses}) {

    const intl = useIntl();


    return (<>
            <label>{intl.formatMessage({id: 'creator.fields.number_of_responses'})}</label>
            <Selector
                options={[
                    {'value': 1, label:'1x'},
                    {'value': 2, label:'2x'},
                    {'value': 3, label:'3x'},
                    {'value': 4, label:'4x'},
                    {'value': 5, label:'5x'},
                    {'value': 6, label:'6x'},
                ]}
                value={numberOfResponses}
                onChange={(option) => setNumberOfResponses(option.value)}
                isSearchable={false}
            />
        </>
    );
}

export default NumberOfResponses;