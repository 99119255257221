import StandardPreview from "./StandardPreview/StandardPreview";
import BrandNamePreview from "./BrandNamePreview/BrandNamePreview";

function PreviewCreation({creation, afterToggleFavorite}) {

    const typesForStandardPreview = [
        'product-description',
        'product-headline',
        'product-review',

        'facebook-ad-copy',
        'google-ad-copy',
        'linkedin-ad-copy',
        'instagram-ad-copy',
        'bing-ad-copy',
        'ad-headline',
        'target-audience',

        'rephrase',
        'idea-to-paragraph',
        'article',
        'expand-sentence',

        'email',

        'facebook-post',
        'instagram-post',
        'linkedin-post',
        'profile-bio',
        'hashtag'
    ]

    const typesForBrandNamePreview = [
        'brand-name'
    ]

    if(typesForStandardPreview.includes(creation.type)) {
        return <StandardPreview
                    creation={creation}
                    afterToggleFavorite={afterToggleFavorite}
                />
    }

    if(typesForBrandNamePreview.includes(creation.type)) {
        return <BrandNamePreview
            creation={creation}
            afterToggleFavorite={afterToggleFavorite}
        />
    }

    return (<div>unknown type</div>
    );
}

export default PreviewCreation;
