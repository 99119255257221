import ClipLoader from "react-spinners/ClipLoader";

function LoadingCompSmall({white = false}) {
    return (
        <div className={"m-auto"}>
            <ClipLoader color={white ? '#ffffff' : '#3FCAB2'} loading={true} size={15} />
        </div>
    );
}

export default LoadingCompSmall;