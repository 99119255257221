import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import axios from "axios";
import errorParser from "../../../ErrorParser/ErrorParser";
import LoadingComp from "../../../LoadingComp/LoadingComp";
import PreviewCreation from "../PreviewCreation/PreviewCreation";
import LoadingCompSmall from "../../../LoadingComp/LoadingCompSmall";

function FavoritesTab({toolId, afterToggleFavorite}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const [creations, setCreations] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    const [initialLoading, setInitialLoading] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (requestedPage = 1) => {

        setLoading(true)

        let payload = {
            type: toolId,
            page: requestedPage
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/creator/creations/favorites", payload, {
            headers: {
                'Authorization': jwt,
                'Content-type': 'application/json'
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                    if (requestedPage === 1) {
                        setCreations(response.data.creations)
                    } else {
                        setCreations([...creations, ...response.data.creations])
                    }
                    setTotal(response.data.total)
                    setPage(requestedPage)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        afterToggleFavorite()
        setInitialLoading(false)
        setLoading(false)
    }

    return (<div className={"tab"}>
            {
                initialLoading ?
                    <LoadingComp/>
                    :
                    creations && creations.length > 0 ?
                        <>
                            {
                                [...creations].map((creation, i) => (
                                    <PreviewCreation
                                        key={creation.id}
                                        creation={creation}
                                        afterToggleFavorite={getData}
                                    />
                                ))
                            }

                            {
                                creations.length < total ?
                                    <div className={"view-more-cont"}>
                                        <div className={"btn btn-light"} onClick={() => getData(page+1)}>
                                            {loading ? <LoadingCompSmall/> : intl.formatMessage({id: 'creator.preview.view_more'})}
                                        </div>
                                    </div>
                                    :null
                            }
                        </>
                        :
                        <div className={"ph"}>
                            <img src={'/assets/images/creator/creations_placeholder.svg'} alt={""}/>
                            <p>{intl.formatMessage({id: 'creator.preview.favorites.placeholder'})}</p>
                        </div>
            }
        </div>
    );
}

export default FavoritesTab;