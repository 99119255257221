import React, {useState} from "react";
import {useIntl} from "react-intl";
import './Login.scss'
import LoadingCompSmall from '../../components/LoadingComp/LoadingCompSmall'
import axios from "axios";
import errorParser from "../../components/ErrorParser/ErrorParser";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";

function ConfirmResetPassword() {

    const intl = useIntl();
    const { token } = useParams();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const resetPassword = async () => {
        //Validate fields
        if (password.length === 0) {
            return
        }

        if(password !== repeatPassword) {
            toast.error(intl.formatMessage({id: 'errors.repeat_password_not_match'}))
            return
        }

        setLoading(true)

        let recaptchaToken = await executeRecaptcha('confirm_reset_password');

        let payload = {
            password: password,
            token: token,
            recaptcha_token: recaptchaToken
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/guest/confirm-reset-password", payload)
            .then((response) => {
                toast.success(intl.formatMessage({id: 'messages.reset_password_success'}))
                localStorage.setItem('jwt', response.data.token)
                window.location.replace("/");
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoading(false)
            })
    }

    return (
        <div className={"cont"}>
            <div className={"login-screen"}>

                <h1 className={"h3 mb-3"}>{intl.formatMessage({id: 'reset_password.title'})}</h1>
                <div className={"form"}>
                    <label htmlFor="hmi_pss">{intl.formatMessage({id: 'login.password'})}</label>
                    <input
                        type="password"
                        className="form-control mb-2"
                        id="hmi_pss"
                        tabIndex={2}
                        autoComplete={"false"}
                        aria-autocomplete={'none'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={intl.formatMessage({id: 'login.password.placeholder'})}/>

                    <label htmlFor="hmi_pss">{intl.formatMessage({id: 'reset_password.repeat_password'})}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="hmi_pss"
                        tabIndex={2}
                        autoComplete={"false"}
                        aria-autocomplete={'none'}
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        placeholder={intl.formatMessage({id: 'reset_password.repeat_password.placeholder'})}/>


                    <button
                        tabIndex={3}
                        type="submit"
                        className="btn btn-primary mt-4 w-100"
                        disabled={loading}
                        onClick={resetPassword}>
                        {
                            loading ?
                                <LoadingCompSmall
                                    white={true}
                                />
                                :
                                intl.formatMessage({id: 'reset_password.button'})
                        }
                    </button>

                </div>

            </div>
        </div>
    );
}

export default ConfirmResetPassword;
