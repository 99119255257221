import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import axios from "axios";
import LoadingCompSmall from "../../../../components/LoadingComp/LoadingCompSmall";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import ToolSwitcher from "../../../../components/Creator/ToolSwitcher/ToolSwitcher";
import CreativityField from "../../../../components/Creator/Fields/CreativityField";
import LengthField from "../../../../components/Creator/Fields/LengthField";
import ToneField from "../../../../components/Creator/Fields/ToneField";
import NumberOfResponsesField from "../../../../components/Creator/Fields/NumberOfResponsesField";
import LanguageContext from "../../../../contexts/LanguageContext";
import {getLanguageIconByValue} from "../../../../functions/general";
import PreviewContainer from "../../../../components/Creator/PreviewContainer/PreviewContainer";

export default function FacebookAdCopy() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const toolId = 'facebook-ad-copy'
    const {inputLanguage, outputLanguage, setShowLanguageModal, setShowSubscriptionLimitModal} = useContext(LanguageContext);

    const [creations, setCreations] = useState([])
    const [productName, setProductName] = useState('')
    const [productDescription, setProductDescription] = useState('')
    const [creativity, setCreativity] = useState(2)
    const [length, setLength] = useState(0)
    const [tone, setTone] = useState(3)
    const [numberOfResponses, setNumberOfResponses] = useState(1)

    //Validation
    const [invalidProductName, setInvalidProductName] = useState(false)
    const [invalidProductDescription, setInvalidProductDescription] = useState(false)
    const [generationLoading, setGenerationLoading] = useState(false)

    const validate = () => {
        //Validate fields
        let invalid = false
        if (productName.length < 2) {
            setInvalidProductName(true)
            invalid = true
        } else {
            setInvalidProductName(false)
        }
        if (productDescription.length < 60) {
            setInvalidProductDescription(true)
            invalid = true
        } else {
            setInvalidProductDescription(false)
        }

        return !invalid
    }

    const generate = async () => {

        if (!validate()) {
            return
        }

        setGenerationLoading(true)

        let payload = {
            productName: productName,
            productDescription: productDescription,
            creativity: creativity,
            length: length,
            tone: tone,
            numberOfResponses: numberOfResponses,
            inputLanguage: inputLanguage,
            outputLanguage: outputLanguage
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/creator/tools/" + toolId, payload, {
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                    setCreations([...response.data.data.results, ...creations])
                }
            })
            .catch(err => {
                errorParser(err, intl, setShowSubscriptionLimitModal)
            })

        setGenerationLoading(false)
    }

    return (
        <div className={"s-tool-cont"}>

            <div className={"inner"} id={"s-inner"}>
                <h4>{intl.formatMessage({id: 'creator.t.' + toolId + ''})}</h4>
                <PreviewContainer
                    creations={creations}
                    setCreations={setCreations}
                    toolId={toolId}
                />
            </div>

            <div className={"right-bar"}>

                {/* Step 1 */}
                <div className={"step"}>
                    <div className={"step-label"}>1</div>
                    <div className={"step-description"}>{intl.formatMessage({id: 'creator.steps.1'})}</div>
                </div>
                <div className={"col-12"}>
                    <ToolSwitcher
                        toolId={toolId}
                    />
                </div>

                {/* Step 2 */}
                <div className={"step mt-4"}>
                    <div className={"step-label"}>2</div>
                    <div className={"step-description"}>{intl.formatMessage({id: 'creator.steps.2'})}</div>
                </div>
                <div>
                    <label htmlFor="product_name"
                           className={"mt-2"}>{intl.formatMessage({id: 'creator.t.' + toolId + '.product_name'})}</label>
                    <div className={"field-right-icon"}>
                        <div className={"right-icon"} onClick={() => setShowLanguageModal(true)}>
                            {getLanguageIconByValue(inputLanguage)}
                        </div>
                        <input type="text"
                               className="form-control"
                               id="product_name"
                               value={productName}
                               onChange={(e) => setProductName(e.target.value)}
                               placeholder={intl.formatMessage({id: 'creator.t.' + toolId + '.product_name_placeholder'})}
                        />
                    </div>
                    {
                        invalidProductName ?
                            <div
                                className="invalid-feedback">{intl.formatMessage({id: 'creator.t.' + toolId + '.product_name.invalid'})}</div>
                            : null
                    }
                </div>
                <div>
                    <label htmlFor="product_description"
                           className={"mt-2"}>{intl.formatMessage({id: 'creator.t.' + toolId + '.product_description'})}</label>
                    <div className={"field-right-icon"}>
                        <div className={"right-icon"} onClick={() => setShowLanguageModal(true)}>
                            {getLanguageIconByValue(inputLanguage)}
                        </div>
                        <textarea
                            className="form-control textarea-normal"
                            id="product_description"
                            value={productDescription}
                            onChange={(e) => setProductDescription(
                                e.target.value.length > 400 ?
                                    e.target.value.substring(0, 400)
                                    : e.target.value
                            )}
                            placeholder={intl.formatMessage({id: 'creator.t.' + toolId + '.product_description.placeholder'})}/>
                    </div>
                    <div className={"textarea-counter"}>{productDescription.length}/400</div>
                    {
                        invalidProductDescription ?
                            <div
                                className="invalid-feedback">{intl.formatMessage({id: 'creator.t.' + toolId + '.product_description.invalid'})}</div>
                            : null
                    }
                </div>

                {/* Step 3 */}
                <div className={"step mt-4"}>
                    <div className={"step-label"}>3</div>
                    <div className={"step-description"}>{intl.formatMessage({id: 'creator.steps.3'})}</div>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <CreativityField
                            creativity={creativity}
                            setCreativity={setCreativity}
                        />
                    </div>
                    <div className={"col-6"}>
                        <LengthField
                            length={length}
                            setLength={setLength}
                        />
                    </div>
                    <div className={"col-6"}>
                        <ToneField
                            tone={tone}
                            setTone={setTone}
                        />
                    </div>
                    <div className={"col-6"}>
                        <NumberOfResponsesField
                            numberOfResponses={numberOfResponses}
                            setNumberOfResponses={setNumberOfResponses}
                        />
                    </div>
                </div>

                <button type={"button"} className={"btn btn-primary mt-4 w-100"} onClick={generate}
                        disabled={generationLoading}>
                    {
                        generationLoading ?
                            <LoadingCompSmall white/>
                            :
                            intl.formatMessage({id: 'creator.buttons.generate'})
                    }
                </button>
            </div>
        </div>
    );
}
