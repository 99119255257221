import {availableLanguages} from "../constants/languages";

export function getLanguageByValue (value) {
    return availableLanguages.find((item) => item.value === value)
}

export function getLanguageIconByValue (value) {
    let language = availableLanguages.find((item) => item.value === value)
    return <span className={"fi fi-" + language.country}></span>
}

export function getLanguageIcon(language) {
    return <span className={"fi fi-" + language.country}></span>
}

export function getTools(intl) {

    return [
        {
            "label": intl.formatMessage({id: 'creator.category.ecommerce'}),
            "options": [
                {
                    value: 'product-description',
                    label: intl.formatMessage({id: 'creator.t.product-description'}),
                    description: intl.formatMessage({id: 'creator.t.product-description.short_description'}),
                    icon: 'product-description.svg'
                },
                {
                    value: 'product-headline',
                    label: intl.formatMessage({id: 'creator.t.product-headline'}),
                    description: intl.formatMessage({id: 'creator.t.product-headline.short_description'}),
                    icon: 'product-headline.svg'
                },
                {
                    value: 'product-review',
                    label: intl.formatMessage({id: 'creator.t.product-review'}),
                    description: intl.formatMessage({id: 'creator.t.product-review.short_description'}),
                    icon: 'product-review.svg'
                },
                {
                    value: 'brand-name',
                    label: intl.formatMessage({id: 'creator.t.brand-name'}),
                    description: intl.formatMessage({id: 'creator.t.brand-name.short_description'}),
                    icon: 'brand-name.svg'
                }
            ]
        },
        {
            "label": intl.formatMessage({id: 'creator.category.ads'}),
            "options": [
                {
                    value: 'facebook-ad-copy',
                    label: intl.formatMessage({id: 'creator.t.facebook-ad-copy'}),
                    description: intl.formatMessage({id: 'creator.t.facebook-ad-copy.short_description'}),
                    icon: 'facebook-ad-copy.svg'
                },
                {
                    value: 'google-ad-copy',
                    label: intl.formatMessage({id: 'creator.t.google-ad-copy'}),
                    description: intl.formatMessage({id: 'creator.t.google-ad-copy.short_description'}),
                    icon: 'google-ad-copy.svg'
                },
                {
                    value: 'instagram-ad-copy',
                    label: intl.formatMessage({id: 'creator.t.instagram-ad-copy'}),
                    description: intl.formatMessage({id: 'creator.t.instagram-ad-copy.short_description'}),
                    icon: 'instagram-ad-copy.svg'
                },
                {
                    value: 'linkedin-ad-copy',
                    label: intl.formatMessage({id: 'creator.t.linkedin-ad-copy'}),
                    description: intl.formatMessage({id: 'creator.t.linkedin-ad-copy.short_description'}),
                    icon: 'linkedin-ad-copy.svg'
                },
                {
                    value: 'bing-ad-copy',
                    label: intl.formatMessage({id: 'creator.t.bing-ad-copy'}),
                    description: intl.formatMessage({id: 'creator.t.bing-ad-copy.short_description'}),
                    icon: 'bing-ad-copy.svg'
                },
                {
                    value: 'ad-headline',
                    label: intl.formatMessage({id: 'creator.t.ad-headline'}),
                    description: intl.formatMessage({id: 'creator.t.ad-headline.short_description'}),
                    icon: 'ad-headline.svg'
                },
                {
                    value: 'target-audience',
                    label: intl.formatMessage({id: 'creator.t.target-audience'}),
                    description: intl.formatMessage({id: 'creator.t.target-audience.short_description'}),
                    icon: 'target-audience.svg'
                }
            ]
        },
        {
            "label": intl.formatMessage({id: 'creator.category.writing'}),
            "options": [
                {
                    value: 'rephrase',
                    label: intl.formatMessage({id: 'creator.t.rephrase'}),
                    description: intl.formatMessage({id: 'creator.t.rephrase.short_description'}),
                    icon: 'rephrase.svg'
                },
                {
                    value: 'idea-to-paragraph',
                    label: intl.formatMessage({id: 'creator.t.idea-to-paragraph'}),
                    description: intl.formatMessage({id: 'creator.t.idea-to-paragraph.short_description'}),
                    icon: 'idea-to-paragraph.svg'
                },
                {
                    value: 'article',
                    label: intl.formatMessage({id: 'creator.t.article'}),
                    description: intl.formatMessage({id: 'creator.t.article.short_description'}),
                    icon: 'article.svg'
                },
                {
                    value: 'expand-sentence',
                    label: intl.formatMessage({id: 'creator.t.expand-sentence'}),
                    description: intl.formatMessage({id: 'creator.t.expand-sentence.short_description'}),
                    icon: 'expand-sentence.svg'
                }
            ]
        },
        {
            "label": intl.formatMessage({id: 'creator.category.email'}),
            "options": [
                {
                    value: 'email',
                    label: intl.formatMessage({id: 'creator.t.email'}),
                    description: intl.formatMessage({id: 'creator.t.email.short_description'}),
                    icon: 'email.svg'
                }
            ]
        },
        {
            "label": intl.formatMessage({id: 'creator.category.social'}),
            "options": [
                {
                    value: 'facebook-post',
                    label: intl.formatMessage({id: 'creator.t.facebook-post'}),
                    description: intl.formatMessage({id: 'creator.t.facebook-post.short_description'}),
                    icon: 'facebook-ad-copy.svg'
                },
                {
                    value: 'instagram-post',
                    label: intl.formatMessage({id: 'creator.t.instagram-post'}),
                    description: intl.formatMessage({id: 'creator.t.instagram-post.short_description'}),
                    icon: 'instagram-ad-copy.svg'
                },
                {
                    value: 'linkedin-post',
                    label: intl.formatMessage({id: 'creator.t.linkedin-post'}),
                    description: intl.formatMessage({id: 'creator.t.linkedin-post.short_description'}),
                    icon: 'linkedin-ad-copy.svg'
                },
                {
                    value: 'profile-bio',
                    label: intl.formatMessage({id: 'creator.t.profile-bio'}),
                    description: intl.formatMessage({id: 'creator.t.profile-bio.short_description'}),
                    icon: 'profile-bio.svg'
                },
                {
                    value: 'hashtag',
                    label: intl.formatMessage({id: 'creator.t.hashtag'}),
                    description: intl.formatMessage({id: 'creator.t.hashtag.short_description'}),
                    icon: 'hashtag.svg'
                }
            ]
        }
    ]
}

export function extractToolsByValue(categoriesAndTools, selectedToolValues) {

    let requestedTools = []

    selectedToolValues.forEach((requestedToolValue) => {
        categoriesAndTools.forEach((category, categoryIndex) => {
            category.options.forEach((option, optionIndex) => {
                if(option.value === requestedToolValue) {
                    requestedTools.push(option)
                }
            })
        })
    })

    return requestedTools
}
