import React, {useState} from "react";
import {useIntl} from "react-intl";
import './Login.scss'
import {Link} from "react-router-dom";
import LoadingCompSmall from '../../components/LoadingComp/LoadingCompSmall'
import axios from "axios";
import errorParser from "../../components/ErrorParser/ErrorParser";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function Login() {

    const intl = useIntl();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)


    const signIn = async () => {
        //Validate fields
        if (email.length === 0 || password.length === 0) {
            return
        }
        setLoading(true)

        let recaptchaToken = await executeRecaptcha('login');

        let payload = {
            email: email,
            password: password,
            recaptcha_token: recaptchaToken
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/guest/login", payload)
            .then((response) => {
                if (response && response.data.token) {
                    localStorage.setItem('jwt', response.data.token)
                    localStorage.setItem('subscriptionType', response.data.subscription_type+'')
                    window.location.replace("/");
                }
            })
            .catch(err => {
                errorParser(err, intl)
                setLoading(false)
            })
    }

    return (
        <div className={"cont"}>


            <div className={"login-screen"}>

                <h1 className={"h3 mb-3"}>{intl.formatMessage({id: 'login.title'})}</h1>
                <div className={"form"}>
                    <label htmlFor="hmi_email">{intl.formatMessage({id: 'login.email'})}</label>
                    <input
                        type="email"
                        className="form-control" id="hmi_email"
                        tabIndex={1}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={intl.formatMessage({id: 'login.email.placeholder'})}/>

                    <div className="mt-3 mb-1"></div>
                    <div className={"float-end"}>
                        <Link to={"/reset-password"}>{intl.formatMessage({id: 'login.reset_password'})}</Link>
                    </div>

                    <label htmlFor="hmi_pss">{intl.formatMessage({id: 'login.password'})}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="hmi_pss"
                        tabIndex={2}
                        autoComplete={"false"}
                        aria-autocomplete={'none'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={intl.formatMessage({id: 'login.password.placeholder'})}/>

                    <button
                        tabIndex={3}
                        type="submit"
                        className="btn btn-primary mt-4 w-100"
                        disabled={loading}
                        onClick={signIn}>
                        {
                            loading ?
                                <LoadingCompSmall
                                    white={true}
                                />
                                :
                                intl.formatMessage({id: 'login.button'})
                        }
                    </button>

                </div>
                <div className={"mt-4"}>{intl.formatMessage({id: 'login.dont_have_an_account'})} <a href={"https://copybase.io"}>{intl.formatMessage({id: 'login.signup'})}</a>
                </div>

                <div className={"text-center mt-5"}>
                    <a href={"https://support.copybase.io"} target={"_blank"} rel={"noreferrer"}>
                        <button type={"button"} className={"btn btn-default mt-2 m-auto"}>
                            <i className={"fa-regular fa-question-circle"}/> {intl.formatMessage({id: 'subscription.go_to_help_desk'})}
                        </button>
                    </a>
                </div>
            </div>

        </div>
    );
}

export default Login;
