import {useIntl} from "react-intl";
import Selector from "../../Selector/Selector";
import {components} from "react-select";
import {availableLanguages} from "../../../constants/languages";

function InputLanguageField({inputLanguage, setInputLanguage}) {

    const intl = useIntl();

    const { Option, SingleValue } = components;
    const IconOption = props => (
        <Option {...props}>
            {
                props.data.country ?
                    <span className={"fi fi-" + props.data.country} style={{marginRight:'5px'}}></span>
                    :
                    props.data.symbol ?
                        <span style={{marginRight:'5px'}}>{props.data.symbol}</span>
                        :null
            }
            {props.data.label} 
        </Option>
    );

    const SingleOption = props => (
        <SingleValue {...props}>
            {
                props.data.country ?
                    <span className={"fi fi-" + props.data.country} style={{marginRight:'5px'}}></span>
                    :
                    props.data.symbol ?
                        <span style={{marginRight:'5px'}}>{props.data.symbol}</span>
                        :null
            }

            {props.data.label}
        </SingleValue>
    )

    return (<>
            <label>{intl.formatMessage({id: 'creator.fields.input_language'})}</label>
            <Selector
                options={availableLanguages.filter((item) => item.country)}
                value={inputLanguage}
                onChange={(option) => {
                    setInputLanguage(option.value)
                }}
                isSearchable={true}
                components={{ Option: IconOption, SingleValue: SingleOption }}
            />
        </>
    );
}

export default InputLanguageField;