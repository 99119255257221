import {useIntl} from "react-intl";
import Selector from "../../Selector/Selector";

function LengthField({length, setLength}) {

    const intl = useIntl();


    return (<>
            <label>{intl.formatMessage({id: 'creator.fields.length'})}</label>
            <Selector
                options={[
                    {'value': 0, label:intl.formatMessage({id: 'creator.fields.length.short'})},
                    {'value': 1, label:intl.formatMessage({id: 'creator.fields.length.long'})},
                ]}
                value={length}
                onChange={(option) => setLength(option.value)}
                isSearchable={false}
            />
        </>
    );
}

export default LengthField;