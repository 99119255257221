import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import axios from "axios";
import LoadingCompSmall from "../../../../components/LoadingComp/LoadingCompSmall";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import ToolSwitcher from "../../../../components/Creator/ToolSwitcher/ToolSwitcher";
import NumberOfResponsesField from "../../../../components/Creator/Fields/NumberOfResponsesField";
import LanguageContext from "../../../../contexts/LanguageContext";
import {getLanguageIconByValue} from "../../../../functions/general";
import PreviewContainer from "../../../../components/Creator/PreviewContainer/PreviewContainer";
import LengthField from "../../../../components/Creator/Fields/LengthField";

export default function Article() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const toolId = 'article'
    const {inputLanguage, outputLanguage, setShowLanguageModal, setShowSubscriptionLimitModal} = useContext(LanguageContext);

    const [creations, setCreations] = useState([])
    const [text, setText] = useState('')
    const [numberOfResponses, setNumberOfResponses] = useState(1)
    const [length, setLength] = useState(0)

    //Validation
    const [invalidText, setInvalidText] = useState(false)
    const [generationLoading, setGenerationLoading] = useState(false)

    const validate = () => {
        //Validate fields
        let invalid = false
        if (text.length < 20) {
            setInvalidText(true)
            invalid = true
        } else {
            setInvalidText(false)
        }

        return !invalid
    }

    const generate = async () => {

        if (!validate()) {
            return
        }

        setGenerationLoading(true)

        let payload = {
            text: text,
            length: length,
            numberOfResponses: numberOfResponses,
            inputLanguage: inputLanguage,
            outputLanguage: outputLanguage
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/creator/tools/" + toolId, payload, {
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                    setCreations([...response.data.data.results, ...creations])
                }
            })
            .catch(err => {
                errorParser(err, intl, setShowSubscriptionLimitModal)
            })

        setGenerationLoading(false)
    }

    return (
        <div className={"s-tool-cont"}>

            <div className={"inner"} id={"s-inner"}>
                <h4>{intl.formatMessage({id: 'creator.t.' + toolId + ''})}</h4>
                <PreviewContainer
                    creations={creations}
                    setCreations={setCreations}
                    toolId={toolId}
                />
            </div>

            <div className={"right-bar"}>

                {/* Step 1 */}
                <div className={"step"}>
                    <div className={"step-label"}>1</div>
                    <div className={"step-description"}>{intl.formatMessage({id: 'creator.steps.1'})}</div>
                </div>
                <div className={"col-12"}>
                    <ToolSwitcher
                        toolId={toolId}
                    />
                </div>

                {/* Step 2 */}
                <div className={"step mt-4"}>
                    <div className={"step-label"}>2</div>
                    <div className={"step-description"}>{intl.formatMessage({id: 'creator.t.' + toolId + '.step.2'})}</div>
                </div>
                <div>
                    <label htmlFor="text"
                           className={"mt-2"}>{intl.formatMessage({id: 'creator.t.' + toolId + '.text'})}</label>
                    <div className={"field-right-icon"}>
                        <div className={"right-icon"} onClick={() => setShowLanguageModal(true)}>
                            {getLanguageIconByValue(inputLanguage)}
                        </div>
                        <textarea
                            className="form-control textarea-normal small"
                            id="text"
                            value={text}
                            onChange={(e) => setText(
                                e.target.value.length > 200 ?
                                    e.target.value.substring(0, 200)
                                    : e.target.value
                            )}
                            placeholder={intl.formatMessage({id: 'creator.t.' + toolId + '.text.placeholder'})}/>
                    </div>
                    <div className={"textarea-counter"}>{text.length}/200</div>
                    {
                        invalidText ?
                            <div
                                className="invalid-feedback">{intl.formatMessage({id: 'creator.t.' + toolId + '.text.invalid'})}</div>
                            : null
                    }
                </div>

                {/* Step 3 */}
                <div className={"step mt-4"}>
                    <div className={"step-label"}>3</div>
                    <div className={"step-description"}>{intl.formatMessage({id: 'creator.steps.3'})}</div>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <LengthField
                            length={length}
                            setLength={setLength}
                        />
                    </div>
                    <div className={"col-6"}>
                        <NumberOfResponsesField
                            numberOfResponses={numberOfResponses}
                            setNumberOfResponses={setNumberOfResponses}
                        />
                    </div>
                </div>

                <button type={"button"} className={"btn btn-primary mt-4 w-100"} onClick={generate}
                        disabled={generationLoading}>
                    {
                        generationLoading ?
                            <LoadingCompSmall white/>
                            :
                            intl.formatMessage({id: 'creator.buttons.generate'})
                    }
                </button>
            </div>
        </div>
    );
}
