import React, {useState} from "react";
import {useIntl} from "react-intl";
import './Login.scss'
import LoadingCompSmall from '../../components/LoadingComp/LoadingCompSmall'
import axios from "axios";
import errorParser from "../../components/ErrorParser/ErrorParser";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {toast} from "react-toastify";

function ResetPassword() {

    const intl = useIntl();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const resetPassword = async () => {
        //Validate fields
        if (email.length === 0) {
            return
        }
        setLoading(true)

        let recaptchaToken = await executeRecaptcha('reset_password');

        let payload = {
            email: email,
            recaptcha_token: recaptchaToken
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/guest/reset-password", payload)
            .then((response) => {
                toast.success(intl.formatMessage({id: 'messages.reset_password_email_sent'}))
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoading(false)
            })
    }

    return (
        <div className={"cont"}>
            <div className={"login-screen"}>

                <h1 className={"h3 mb-3"}>{intl.formatMessage({id: 'reset_password.title'})}</h1>
                <div className={"form"}>
                    <label htmlFor="hmi_email">{intl.formatMessage({id: 'login.email'})}</label>
                    <input
                        type="email"
                        className="form-control" id="hmi_email"
                        tabIndex={1}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={intl.formatMessage({id: 'login.email.placeholder'})}/>


                    <button
                        tabIndex={3}
                        type="submit"
                        className="btn btn-primary mt-4 w-100"
                        disabled={loading}
                        onClick={resetPassword}>
                        {
                            loading ?
                                <LoadingCompSmall
                                    white={true}
                                />
                                :
                                intl.formatMessage({id: 'reset_password.button'})
                        }
                    </button>

                </div>

            </div>
        </div>
    );
}

export default ResetPassword;
