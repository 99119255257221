import {useIntl} from "react-intl";
import Selector from "../../Selector/Selector";
import {components} from "react-select";
import ModalComp from "../../ModalComp/ModalComp";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

function CreativityField({creativity, setCreativity}) {

    const intl = useIntl();
    const navigate = useNavigate()
    const [showProModal, setShowProModal] = useState(false)

    const {Option} = components;
    const ItemOption = props => (
        <div onClick={(event) => {
            if(props.isDisabled)
                setShowProModal(true)
        }}>
        <Option {...props} className={props.isDisabled ? "cursor-pointer" : ""}>
            {props.label}

        </Option>
        </div>
    );

    let options = [
        {value: 0, label: intl.formatMessage({id: 'creator.fields.creativity.low'})},
        {value: 1, label: intl.formatMessage({id: 'creator.fields.creativity.medium'})},
        {value: 2, label: intl.formatMessage({id: 'creator.fields.creativity.high'})}
    ]

    if (localStorage.getItem('subscriptionType') === '1') {
        options.push(
            {
                value: 3,
                label: <>
                    {intl.formatMessage({id: 'creator.fields.creativity.best'})}
                    <div className={"badge bg-primary ms-2"}>PRO</div>
                </>,
                disabled: true
            }
        )
    } else {
        options.push({value: 3, label: intl.formatMessage({id: 'creator.fields.creativity.best'})})
    }


    return (<>
            <label>{intl.formatMessage({id: 'creator.fields.creativity'})}</label>
            <Selector
                options={options}
                value={creativity}
                onChange={(option) => setCreativity(option.value)}
                isSearchable={false}
                isOptionDisabled={(option) => option.disabled}
                components={{Option: ItemOption}}
            />

            <ModalComp
                isOpen={showProModal}
                onRequestClose={() => setShowProModal(false)}
                className={"subscription-limit"}
            >
                <div className={"d-flex flex-column justify-content-center align-items-center flex-grow-1"}>

                    <img src={"/assets/images/subscription_limit.svg"} className={"image"} alt=""/>
                    <h5 className={"text-center"}>{intl.formatMessage({id: 'subscription_limit_creativity.title'})}</h5>
                    <p className={"text-center mt-2"}>{intl.formatMessage({id: 'subscription_limit_creativity.description'})}</p>

                    <button type={"button"} className={"btn btn-primary mt-4"} onClick={() => {
                        setShowProModal(false)
                        navigate('/subscription')
                    }}>
                        {intl.formatMessage({id: 'subscription_limit_creativity.button'})}
                    </button>

                </div>
            </ModalComp>
        </>
    );
}

export default CreativityField;
