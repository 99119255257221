import './ToolSwitcher.scss'
import {useIntl} from "react-intl";
import {components} from 'react-select'
import {useNavigate } from 'react-router-dom'
import Selector from "../../Selector/Selector";
import {getTools} from "../../../functions/general";

function ToolSwitcher({toolId}) {

    const intl = useIntl();
    const navigate = useNavigate()
    const optionsGroups = getTools(intl)

    const { Option, SingleValue } = components;
    const IconOption = props => (
        <Option {...props}>
            <img
                src={require('../../../assets/images/tools/' + props.data.icon)}
                style={{ width: 18, marginRight: '5px' }}
                alt={props.data.label}
            />
            {props.data.label}
        </Option>
    );

    const SingleOption = props => (
        <SingleValue {...props}>
            <img
                src={require('../../../assets/images/tools/' + props.data.icon)}
                style={{ width: 18, marginRight: '5px' }}
                alt={props.data.label}
            />
            {props.data.label}
        </SingleValue>
    )

    return (<>
            <Selector
                options={optionsGroups}
                value={toolId}
                onChange={(selectedOption) => {
                    if(selectedOption && selectedOption.value) {
                        navigate('/creator/tools/' + selectedOption.value)
                    }
                }}
                isSearchable={true}
                components={{ Option: IconOption, SingleValue: SingleOption }}
            />
        </>
    );
}

export default ToolSwitcher;
