import {useIntl} from "react-intl";
import './Creator.scss'
import {useNavigate} from "react-router-dom";
import {getTools} from "../../functions/general";
import {useEffect} from "react";

function Creator() {

    const intl = useIntl();
    const navigate = useNavigate()
    const categoriesAndTools = getTools(intl)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        })
    }, [])

    return (
        <div className={"inner creator"}>

            <h2>{intl.formatMessage({id: 'creator.select_a_tool'})}</h2>

            <div className={"tools"}>
                    {
                       categoriesAndTools.map((category,i) => (
                           <div key={i} className={"category"}>
                               <div className={"category-title"}>{category.label}</div>

                               <div className={"items"}>
                                   {
                                       category.options.map((tool,j) => (
                                           <div key={j} className={"item"} onClick={() => navigate('/creator/tools/' + tool.value)}>
                                               <img src={require('../../assets/images/tools/' + tool.icon)} alt={""}/>
                                               <div className={"title"}>{tool.label}</div>
                                               <div className={"description"}>{tool.description}</div>
                                               <div className={"button"}>{intl.formatMessage({id: 'creator.open_tool'})}</div>
                                           </div>
                                       ))
                                   }
                               </div>
                           </div>
                       ))
                    }
            </div>

        </div>
    );
}

export default Creator;
