import React, {useState} from "react";
import './StandardPreview.scss'
import {useIntl} from "react-intl";
import {getLanguageByValue, getLanguageIcon} from "../../../../../functions/general";
import axios from "axios";
import errorParser from "../../../../ErrorParser/ErrorParser";

function StandardPreview({creation, afterToggleFavorite}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const [favorite, setFavorite] = useState(creation.favorite)
    const [loadingFavorite, setLoadingFavorite] = useState(false)

    const language = getLanguageByValue(creation.language)

    const toggleFavorite = async () => {
        setLoadingFavorite(true)

        let payload = {
            id: creation.id,
        }

        await axios.post(process.env.REACT_APP_SERVER + "/api/creator/creations/toggleFavorite", payload, {
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                   setFavorite(response.data.favorite)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        afterToggleFavorite()
        setLoadingFavorite(false)
    }

    return (<div className={"standard-preview"}>

            <div className={"value"}
                dangerouslySetInnerHTML={{__html: creation.text.replaceAll('\n', '<br/>')}}
                dir={language.rtl ? "rtl" : ""}></div>

            <div className={"buttons"}>
                <div className={"language"}>
                    {getLanguageIcon(language)}
                </div>

                <button type={"button"}
                        className={"btn btn-light btn-favorite"}
                        onClick={() => toggleFavorite()}
                        disabled={loadingFavorite}>
                    <i className={(favorite ? 'fa-solid fa-heart active' : 'fa-regular fa-heart')}/> {intl.formatMessage({id: 'creator.preview.favorite'})}
                </button>

                <button type={"button"}
                        className={"btn btn-light"}
                        onClick={() => navigator.clipboard.writeText(creation.text)}
                ><i className="fa-regular fa-copy"/> {intl.formatMessage({id: 'creator.preview.copy_to_clipboard'})}
                </button>
            </div>
        </div>
    );
}

export default StandardPreview;