import React, {useState} from "react";
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom'
import {IntlProvider} from 'react-intl'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";


import {en} from './translations/en/en'
import {ro} from './translations/ro/ro'

import Login from "./screens/Login/Login";
import LayoutGuest from "./components/LayoutGuest/LayoutGuest";
import Layout from "./components/Layout/Layout";
import Dashboard from "./screens/Dashboard/Dashboard";
import ProductDescription from "./screens/Creator/Tools/Ecommerce/ProductDescription";
import Creator from "./screens/Creator/Creator";
import LanguageContext from "./contexts/LanguageContext"
import Modal from 'react-modal';
import ResetPassword from "./screens/Login/ResetPassword";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import ConfirmResetPassword from "./screens/Login/ConfirmResetPassword";
import Account from "./screens/Account/Account";
import Subscription from "./screens/Subscription/Subscription";
import ThankYou from "./screens/ThankYou/ThankYou";
import Rephrase from "./screens/Creator/Tools/Writing/Rephrase";
import IdeaToParagraph from "./screens/Creator/Tools/Writing/IdeaToParagraph";
import ProductReview from "./screens/Creator/Tools/Ecommerce/ProductReview";
import FacebookAdCopy from "./screens/Creator/Tools/Ads/FacebookAdCopy";
import ProductHeadline from "./screens/Creator/Tools/Ecommerce/ProductHeadline";
import GoogleAdCopy from "./screens/Creator/Tools/Ads/GoogleAdCopy";
import Article from "./screens/Creator/Tools/Writing/Article";
import AdHeadline from "./screens/Creator/Tools/Ads/AdHeadline";
import TargetAudience from "./screens/Creator/Tools/Ads/TargetAudience";
import Email from "./screens/Creator/Tools/Email/Email";
import BrandName from "./screens/Creator/Tools/Ecommerce/BrandName";
import ExpandSentence from "./screens/Creator/Tools/Writing/ExpandSentence";
import ProfileBio from "./screens/Creator/Tools/Social/ProfileBio";
import Hashtag from "./screens/Creator/Tools/Social/Hashtag";
import LinkedInAdCopy from "./screens/Creator/Tools/Ads/LinkedInAdCopy";
import InstagramAdCopy from "./screens/Creator/Tools/Ads/InstagramAdCopy";
import FacebookPost from "./screens/Creator/Tools/Social/FacebookPost";
import InstagramPost from "./screens/Creator/Tools/Social/InstagramPost";
import LinkedInPost from "./screens/Creator/Tools/Social/LinkedInPost";
import BingAdCopy from "./screens/Creator/Tools/Ads/BingAdCopy";
import ThankYouJvz from "./screens/ThankYou/ThankYouJvz";
import ThankYouWp from "./screens/ThankYou/ThankYouWp";


Modal.setAppElement('body');

function App() {

    const [language, setLanguage] = useState('en')
    const [inputLanguage, setInputLanguage] = useState(localStorage.getItem('inputLanguage') ? localStorage.getItem('inputLanguage') : 'en')
    const [outputLanguage, setOutputLanguage] = useState(localStorage.getItem('outputLanguage') ? localStorage.getItem('outputLanguage') : 'en')
    const [showLanguageModal, setShowLanguageModal] = useState(false)
    const [showSubscriptionLimitModal, setShowSubscriptionLimitModal] = useState(false)

    const isJwt = localStorage.getItem('jwt') !== null

    const getLanguageFile = (lang) => {
        switch (lang) {
            case 'en':
                return en
            case 'ro':
                return ro
            default:
                return en
        }
    }

    const changeLanguage = (lang) => {
        setLanguage(lang)
        localStorage.setItem('language', lang)
    }
    const changeInputLanguage = (lang) => {
        setInputLanguage(lang)
        localStorage.setItem('inputLanguage', lang)
    }
    const changeOutputLanguage = (lang) => {
        setOutputLanguage(lang)
        localStorage.setItem('outputLanguage', lang)
    }
    const languageContextValue = {
        language: language,
        setLanguage: changeLanguage,
        inputLanguage: inputLanguage,
        setInputLanguage: changeInputLanguage,
        outputLanguage: outputLanguage,
        setOutputLanguage: changeOutputLanguage,
        showLanguageModal: showLanguageModal,
        setShowLanguageModal: setShowLanguageModal,
        showSubscriptionLimitModal: showSubscriptionLimitModal,
        setShowSubscriptionLimitModal: setShowSubscriptionLimitModal
    };

    return (
        <LanguageContext.Provider value={languageContextValue}>
            <IntlProvider messages={getLanguageFile(language)} locale={language}>
                <ToastContainer/>

                {
                    !isJwt ?
                        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
                            <BrowserRouter>
                                <Routes>
                                    <Route element={<LayoutGuest/>}>
                                        <Route exact path="/" element={<Login/>}/>
                                        <Route path="/reset-password" element={<ResetPassword/>}/>
                                        <Route path="/confirm-reset-password/:token" element={<ConfirmResetPassword/>}/>
                                    </Route>
                                    <Route exact path="/thank-you" element={<ThankYou/>}/>
                                    <Route exact path="/thank-you-jvz" element={<ThankYouJvz/>}/>
                                    <Route exact path="/thank-you-wp" element={<ThankYouWp/>}/>
                                </Routes>
                            </BrowserRouter>
                        </GoogleReCaptchaProvider>
                        :
                        <BrowserRouter>
                            <Routes>
                                <Route element={<Layout/>}>
                                    <Route exact path="/" element={<Dashboard/>}/>

                                    <Route exact path="/creator" element={<Creator/>}/>

                                    {/* Ecommerce */}
                                    <Route exact path="/creator/tools/product-description"
                                           element={<ProductDescription/>}/>
                                    <Route exact path="/creator/tools/product-headline"
                                           element={<ProductHeadline/>}/>
                                    <Route exact path="/creator/tools/product-review"
                                           element={<ProductReview/>}/>
                                    <Route exact path="/creator/tools/brand-name"
                                           element={<BrandName/>}/>

                                    {/* Ads */}
                                    <Route exact path="/creator/tools/facebook-ad-copy"
                                           element={<FacebookAdCopy/>}/>
                                    <Route exact path="/creator/tools/google-ad-copy"
                                           element={<GoogleAdCopy/>}/>
                                    <Route exact path="/creator/tools/linkedin-ad-copy"
                                           element={<LinkedInAdCopy/>}/>
                                    <Route exact path="/creator/tools/instagram-ad-copy"
                                           element={<InstagramAdCopy/>}/>
                                    <Route exact path="/creator/tools/bing-ad-copy"
                                           element={<BingAdCopy/>}/>
                                    <Route exact path="/creator/tools/ad-headline"
                                           element={<AdHeadline/>}/>
                                    <Route exact path="/creator/tools/target-audience"
                                           element={<TargetAudience/>}/>

                                    {/* Writing */}
                                    <Route exact path="/creator/tools/idea-to-paragraph"
                                           element={<IdeaToParagraph/>}/>
                                    <Route exact path="/creator/tools/rephrase"
                                           element={<Rephrase/>}/>
                                    <Route exact path="/creator/tools/article"
                                           element={<Article/>}/>
                                    <Route exact path="/creator/tools/expand-sentence"
                                           element={<ExpandSentence/>}/>

                                    {/* Email */}
                                    <Route exact path="/creator/tools/email"
                                           element={<Email/>}/>

                                    {/* Social */}
                                    <Route exact path="/creator/tools/facebook-post"
                                           element={<FacebookPost/>}/>
                                    <Route exact path="/creator/tools/instagram-post"
                                           element={<InstagramPost/>}/>
                                    <Route exact path="/creator/tools/linkedin-post"
                                           element={<LinkedInPost/>}/>
                                    <Route exact path="/creator/tools/profile-bio"
                                           element={<ProfileBio/>}/>
                                    <Route exact path="/creator/tools/hashtag"
                                           element={<Hashtag/>}/>

                                    <Route exact path="/subscription" element={<Subscription/>}/>
                                    <Route exact path="/account" element={<Account/>}/>

                                </Route>
                                <Route exact path="/thank-you" element={<ThankYou/>}/>
                            </Routes>
                        </BrowserRouter>
                }


            </IntlProvider>
        </LanguageContext.Provider>
    );
}

export default App;
