import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import './Account.scss'
import axios from "axios";
import errorParser from "../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../components/LoadingComp/LoadingComp";
import LoadingCompSmall from "../../components/LoadingComp/LoadingCompSmall";
import {toast} from "react-toastify";

function Account() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [accountDetails, setAccountDetails] = useState({})
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const [changePassword, setChangePassword] = useState(false)
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        await axios.get(process.env.REACT_APP_SERVER + "/api/account", {
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                    setAccountDetails(response.data.data)
                    setFirstName(response.data.data.first_name)
                    setLastName(response.data.data.last_name)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setLoading(false)
    }

    const saveData = async () => {

        let payload = {
            first_name: firstName,
            last_name: lastName
        }

        //Validate fields
        if(changePassword) {
            if (password.length === 0) {
                return
            }

            if(password !== repeatPassword) {
                toast.error(intl.formatMessage({id: 'errors.repeat_password_not_match'}))
                return
            }

            //add password in payload
            payload.password = password
        }

        setSaveLoading(true)


        await axios.post(process.env.REACT_APP_SERVER + "/api/account", payload, {
            headers: {
                'Authorization': jwt
            }
        })
            .then((response) => {
                if (response && response.data.status === 1) {
                    toast.success(intl.formatMessage({id: 'messages.data_has_been_saved_successfully'}))
                    setPassword('')
                    setRepeatPassword('')
                    setChangePassword(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    if (loading) {
        return (
            <LoadingComp/>
        )
    }

    return (
        <div className={"inner account"}>

            <h2>{intl.formatMessage({id: 'account.title'})}</h2>

            <div className={"inner-cont mt-3"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"form"}>
                        <label htmlFor="email">{intl.formatMessage({id: 'account.email'})}</label>
                        <input
                            type="text"
                            className="form-control mb-1" id="email"
                            tabIndex={1}
                            value={accountDetails.email}
                            readOnly={true}
                            disabled={true}
                            onChange={(e) => {}}
                            placeholder={intl.formatMessage({id: 'account.email'})}/>

                        <label htmlFor="first_name">{intl.formatMessage({id: 'account.first_name'})}</label>
                        <input
                            type="text"
                            className="form-control mb-1" id="first_name"
                            tabIndex={1}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder={intl.formatMessage({id: 'account.first_name'})}/>

                        <label htmlFor="last_name">{intl.formatMessage({id: 'account.last_name'})}</label>
                        <input
                            type="text"
                            className="form-control mb-1" id="last_name"
                            tabIndex={1}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder={intl.formatMessage({id: 'account.last_name'})}/>

                        <div className="mt-2 form-check">
                            <input type="checkbox" className="form-check-input" id="changePassword" checked={changePassword}
                                   onChange={() => {
                                       setPassword('')
                                       setRepeatPassword('')
                                       setChangePassword(!changePassword)
                                   }}/>
                                <label className="form-check-label" htmlFor="changePassword">{intl.formatMessage({id: 'account.want_to_change_password'})}</label>
                        </div>

                        {
                            changePassword ?
                                <>
                                    <label htmlFor="hmi_pss">{intl.formatMessage({id: 'login.password'})}</label>
                                    <input
                                        type="password"
                                        className="form-control mb-2"
                                        id="hmi_pss"
                                        tabIndex={2}
                                        autoComplete={"false"}
                                        aria-autocomplete={'none'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder={intl.formatMessage({id: 'login.password.placeholder'})}/>

                                    <label
                                        htmlFor="hmi_pss">{intl.formatMessage({id: 'reset_password.repeat_password'})}</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="hmi_pss"
                                        tabIndex={2}
                                        autoComplete={"false"}
                                        aria-autocomplete={'none'}
                                        value={repeatPassword}
                                        onChange={(e) => setRepeatPassword(e.target.value)}
                                        placeholder={intl.formatMessage({id: 'reset_password.repeat_password.placeholder'})}/>
                                </>
                                : null
                        }

                        <button
                            type="submit"
                            className="btn btn-primary mt-3 w-100"
                            disabled={saveLoading}
                            onClick={saveData}>
                            {
                                saveLoading ?
                                    <LoadingCompSmall
                                        white={true}
                                    />
                                    :
                                    intl.formatMessage({id: 'buttons.save'})
                            }
                        </button>
                    </div>
                </div>
            </div>

            </div>
        </div>
    );
}

export default Account;